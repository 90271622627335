import { IonSpinner } from "@ionic/react";
import { connect } from "react-redux";

import {
  setBasketList,
  setOfferList,
} from "../../../redux/offer-list/offer-list.actions";
import { MemberCategories } from "../member-categories/member-categories";
import { OfferCard } from "../offer-card/offer-card";
import {useEffect} from "react";

const mapStateToProps = (state: any) => {
  return {
    count: state.counter.count,
    offerDetails: state.offerDetails,
    cards: state.cards.holders,
    categories: state.categories.categories,
    selectedCategory: state.categories.selectedCategory,
    offers: state.offerList.offers || [],
    nextgenOffers: state.offerList.nextgenOffers || [],
    nextgenIds: state.offerList.nextgenIds || [],
    nonBestOffers: state.offerList.nonBestOffers || [],
    bestOffers: state.offerList.bestOffers || [],
    offersLoading: state.offerList.offersLoading,
    basket: state.offerList.basket,
    user: state.global.user,
    retailer:state.retailers.selected,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setOfferList: (data: any) => dispatch(setOfferList(data)),
    setBasketList: (data: any) => dispatch(setBasketList(data)),
  };
};

interface Props {
  offers: any[];
  cards: any[];
  nextgenOffers: any[];
  nextgenIds: any[];
  nonBestOffers: any[];
  bestOffers: any[];
  offersLoading: boolean;
  selectedCategory: any;
  basket: any[];
  categories?: any[];
  setOfferList: Function;
  setBasketList: Function;
  fetchOffers?: Function;
  user:any;
  selectedStore:any;
  retailer:any;
  homeScreenMode?: boolean;
}

export const OffersWithCategoriesComponent = (props: Props) => {
  const basketOfferIds = props.basket.map((b) => b.offer.id);


  useEffect(() => {
    // let retailer = props.retailer ? props.retailer : props.selectedStore.retailer ? props.selectedStore.retailer : {id:-1}
    // let rtv = retailer && retailer.rtv_card_prefix ? retailer.rtv_card_prefix.replace(/^\D+/g, '').replace(/^0+/, ''): "";
    // console.log(rtv)
    // const holders = props.cards;
    //
    // let selectedStoreRetailerId = props.selectedStore ? parseInt(props.selectedStore.retailer_id) : -1;
    // let holder = props.cards.filter(
    //     (c) => c.retailer_id === selectedStoreRetailerId
    // )[0]
    //
    //
    // if(props.nextgenOffers.length>0 && props.user){
    //   let url = `https://catalinamarketing-japan-np.azure-api.net/collect?t=pageview&cs=mobile&cc=JPN&network_id=${rtv}&an=LCM&av=3.2&cid=${retailer.rtv_card_prefix}-${holder.ref}&ts=${Date.now()}&ads=${props.nextgenOffers.toString()}&ca=member_id:${props.user.id}&ca=retailer_id:${retailer.id}&ca=offer_id:${props.nextgenIds.join(';')}`
    //   const options = {
    //     headers: {
    //       'Host': 'catalinamarketing-japan-np.azure-api.net',
    //       'Ocp-Apim-Subscription-Key': '5a7dacff5e2441ac98a972b0aa7cc70c',
    //       'product': 'cwallet-api'
    //     }
    //   };
    //   fetch(url, options)
    //       .then(res => res.blob())
    //       .then(blob => {
    //         console.log(blob);
    //       });
    // }
  }, [props.nextgenOffers]);
  let retailer = props.retailer ? props.retailer : props.selectedStore.retailer ? props.selectedStore.retailer : {id:-1}
  let rtv = retailer && retailer.rtv_card_prefix ? retailer.rtv_card_prefix.replace(/^\D+/g, '').replace(/^0+/, ''): "";
  console.log(rtv)
  const holders = props.cards;
  let storeId = props.selectedStore?props.selectedStore.id:-1
  let selectedStoreRetailerId = props.selectedStore ? parseInt(props.selectedStore.retailer_id) : -1;
  let holder = props.cards.filter(
      (c) => c.retailer_id === selectedStoreRetailerId
  )[0]
  let firePixelAPI = (offer:any) => {
    console.log(offer.is_nextgen_offer);
    if(offer.is_nextgen_offer){
        const COLLECT_URL= process.env.REACT_APP_COLLECT_API as string;
      let url = `${COLLECT_URL}?t=pageview&cs=mobile&cc=JPN&network_id=${rtv}&an=LCM&av=3.2&cid=${retailer.rtv_card_prefix}-${holder.ref}&ts=${Date.now()}&ads=${offer.bl_message_ref}&ca=member_id:${props.user.id}&ca=retailer_id:${retailer.id}&ca=offer_id:${offer.id}&ca=cw_store_id:${storeId}`
      const options = {
        headers: {
          'Host': process.env.REACT_APP_COLLECT_API_HOST as string,
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_COLLECT_API_SUBSCRIPTION_KEY as string,
          'product': 'cwallet-api'
        }
      };
      fetch(url, options)
          .then(res => res.blob())
          .then(blob => {
            console.log(blob);
          });

    }
    // if(props.nextgenOffers.length>0 && props.user){

  }
  return (
    <>
      <MemberCategories fetchOffers={props.fetchOffers} />
      {props.offersLoading && (
        <div className="flex justify-center">
          <IonSpinner />
        </div>
      )}
      {/*{props.categories.length > 0 &&*/}
      {/*  <div className="grid grid-cols-2 gap-2 px-2 py-2 pt-3" style={{ background: "#f5f5f5" }}>*/}
      {/*    {props.offers.map((offer, index) => (*/}
      {/*      <OfferCard*/}
      {/*        offer={offer}*/}
      {/*        key={offer.id}*/}
      {/*        added={basketOfferIds.indexOf(offer.id) > -1}*/}
      {/*        homeScreenMode={props.homeScreenMode}*/}
      {/*        expiredAt={""}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*}*/}
      {props.selectedCategory && props.selectedCategory.id === 1 && !props.offersLoading?
        <>
          {props.nextgenOffers.length>0 &&
              <>
                {/*<img*/}
                {/*    className="hidden"*/}
                {/*    // src="http://dmp-edge-node-01-dev-v04-japaneast.catmktg.jp:80/dmp/rest/events/collect?t=pageview&cs=mobile&cc=JPN&network_id=32&an=LCM&av=3.2&cid=JPN-0018-6900172830934343&ts=1713421441000&ads=${}&ca=member_id:12345&ca=retailer_id:1&ca=offer_id:11111;22222;33333;44444;55555"*/}
                {/*    src={``}*/}
                {/*    />*/}
              </>
          }
          {props.bestOffers && props.bestOffers.length > 0 &&
              <div className="parent bg-white">
                {/*<div className="h-2 mt-2 bg-white" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
                <div className="p-2 " style={{background: "#f5f5f5"}}>
                  <div className="">
                    <div className="mx-2 p-4 font-bold text-sm flex items-center rounded-md">
                  {/*<StarIcon className="h-5 w-5 mr-1" />*/}
                  あなたへの特別クーポン

                </div>
                {props.categories && props.categories.length > 0 && !props.offersLoading &&
                    <div className="grid grid-cols-2 gap-1 px-1 py-1 pt-1" >
                      {props.bestOffers.length > 0 && props.bestOffers.map((offer, index) => (
                          <OfferCard
                              pixelApi = {firePixelAPI}
                              offer={offer}
                              bestMode={true}
                              key={offer.id}
                              added={basketOfferIds.indexOf(offer.id) > -1}
                              homeScreenMode={props.homeScreenMode}
                              expiredAt={""}
                          />
                      ))}
                    </div>
                }
              </div>
            </div>

          </div>
          }
          {props.nonBestOffers.length > 0 &&
              <div className="parent bg-white" >
                {/*<div className="h-2 mt-2 bg-white" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
            {/*<div className="h-2" style={{ background: "#ffffff" }}>&nbsp;</div>*/}
            <div className="p-2" style={{ background: "#f5f5f5" }} >
              <div className=" ">
                <div className="mx-2 p-4 font-bold text-sm flex items-center  rounded-md">
                  すべてのクーポン
                </div>
                {props.categories && props.categories.length > 0 &&
                    <div className="grid grid-cols-2 gap-2 px-1 py-1 pt-1">
                      {props.nonBestOffers.length > 0 && props.nonBestOffers.map((offer, index) => (
                          <OfferCard
                              pixelApi = {firePixelAPI}
                              offer={offer}
                              key={offer.id}
                              bestMode={true}
                              added={basketOfferIds.indexOf(offer.id) > -1}
                              homeScreenMode={props.homeScreenMode}
                              expiredAt={""}
                          />
                      ))}
                    </div>
                }
              </div>
            </div>

          </div>
          }
        </> : <>
            {/*<div className="h-2 mt-2 bg-white" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
            {props.categories.length > 0 && !props.offersLoading &&
                <div className="grid grid-cols-2 gap-2 px-2 py-2 pt-3" style={{ background: "#f5f5f5" }}>
                  {props.offers.map((offer, index) => (
                      <OfferCard
                          offer={offer}
                          pixelApi = {firePixelAPI}
                          key={offer.id}
                          bestMode={true}
                          added={basketOfferIds.indexOf(offer.id) > -1}
                          homeScreenMode={props.homeScreenMode}
                          expiredAt={""}
                      />
                  ))}
                </div>
            }
            {/*<div className="h-2" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
          </>
      }
      {/*{props.selectedCategory && !props.offersLoading?*/}
      {/*  <>*/}
      {/*    {props.bestOffers && props.bestOffers.length > 0 &&*/}
      {/*    <div className="parent bg-white" >*/}
      {/*      <div className="h-2 mt-2 bg-white" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
      {/*      <div className="p-2 " >*/}
      {/*        <div className="">*/}
      {/*          <div className="mx-2 p-4 font-bold text-sm flex items-center rounded-md">*/}
      {/*            /!*<StarIcon className="h-5 w-5 mr-1" />*!/*/}
      {/*            あなたへの特別クーポン*/}

      {/*          </div>*/}
      {/*          {props.categories.length > 0 && !props.offersLoading &&*/}
      {/*              <div className="grid grid-cols-2 gap-1 px-1 py-1 pt-1" >*/}
      {/*                {props.bestOffers.length > 0 && props.bestOffers.map((offer, index) => (*/}
      {/*                    <OfferCard*/}
      {/*                        offer={offer}*/}
      {/*                        bestMode={true}*/}
      {/*                        key={offer.id}*/}
      {/*                        added={basketOfferIds.indexOf(offer.id) > -1}*/}
      {/*                        homeScreenMode={props.homeScreenMode}*/}
      {/*                        expiredAt={""}*/}
      {/*                    />*/}
      {/*                ))}*/}
      {/*              </div>*/}
      {/*          }*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*    }*/}
      {/*    {props.nonBestOffers.length > 0 &&*/}
      {/*        <div className="parent bg-white" >*/}
      {/*      <div className="h-2" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
      {/*      <div className="p-2" >*/}
      {/*        <div className=" ">*/}
      {/*          <div className="mx-2 p-4 font-bold text-sm flex items-center  rounded-md">*/}
      {/*            すべてのクーポン*/}
      {/*          </div>*/}
      {/*          {props.categories.length > 0 &&*/}
      {/*              <div className="grid grid-cols-2 gap-1 px-1 py-1 pt-1">*/}
      {/*                {props.nonBestOffers.length > 0 && props.nonBestOffers.map((offer, index) => (*/}
      {/*                    <OfferCard*/}
      {/*                        offer={offer}*/}
      {/*                        key={offer.id}*/}
      {/*                        bestMode={true}*/}
      {/*                        added={basketOfferIds.indexOf(offer.id) > -1}*/}
      {/*                        homeScreenMode={props.homeScreenMode}*/}
      {/*                        expiredAt={""}*/}
      {/*                    />*/}
      {/*                ))}*/}
      {/*              </div>*/}
      {/*          }*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*    }*/}
      {/*  </> : <>*/}
      {/*      {props.categories.length > 0 && !props.offersLoading &&*/}
      {/*          <div className="grid grid-cols-2 gap-2 px-2 py-2 pt-3">*/}
      {/*            {props.offers.map((offer, index) => (*/}
      {/*                <OfferCard*/}
      {/*                    offer={offer}*/}
      {/*                    key={offer.id}*/}
      {/*                    bestMode={false}*/}
      {/*                    added={basketOfferIds.indexOf(offer.id) > -1}*/}
      {/*                    homeScreenMode={props.homeScreenMode}*/}
      {/*                    expiredAt={""}*/}
      {/*                />*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*      }*/}
      {/*      <div className="h-2" style={{ background: "#f5f5f5" }}>&nbsp;</div>*/}
      {/*    </>*/}
      {/*}*/}

      {(props.categories.length === 0 || (props.nonBestOffers.length === 0 && props.bestOffers.length === 0)) &&
        <div
          className="my-5 text-center text-black"
        >
          <span className="font-bold text-xl">Coming Soon</span><br/>
          <span className="text-sm">掲載までしばらくお待ちください</span>
        </div>
      }


    </>
  );
};

export const OffersWithCategories = connect(
  mapStateToProps,
  mapDispatchToProps
)(OffersWithCategoriesComponent);
