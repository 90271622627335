import {
  SET_BASKET_LIST,
  SET_OFFER_LIST,
  SET_OFFERS_LOADING,
} from "./offer-list.types";
import MembersService from "../../line/services/members.service";
import { SET_CATEGORIES } from "../categories/categories.types";

export const setOfferList = (offers) => {
  return {
    type: SET_OFFER_LIST,
    payload: offers,
  };
};

export const offerListLoading = (loading) => {
  return {
    type: SET_OFFERS_LOADING,
    payload: loading,
  };
};

export const fetchOffers = (catId) => {

  return (dispatch, getState) => {
    const { stores, categories, cards } = getState()
    let cId = catId ? catId : categories.selectedCategory  ? categories.selectedCategory.id: 1
    MembersService.getCoupons(stores.selectedStore ? stores.selectedStore.id : 0,
        cId,
      stores.selectedStore ? stores.selectedStore.retailer_id : cards && cards.holders && cards.holders.length > 0 ? cards.holders[0].retailer_id : -1
    )
      .then((responseJson) => {
        let offers = []
        let bestOffers = [];
        responseJson.data.items.forEach((offer)=>{
          if(offer.hasOwnProperty('default_target') && offer.default_target === false){
            bestOffers.push(offer)
          } else {
            offers.push(offer);
          }
        })

        const first6 = offers.slice(0, 6);
        dispatch({
          type: SET_OFFER_LIST,
          payload: [offers,bestOffers, responseJson.data.items, first6]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchOffersForHomePage = (catId) => {
  return dispatch => {
    MembersService.getCouponsForHomePage(catId)
      .then((responseJson) => {
        const first6 = responseJson.data.items.slice(0, 6);
        dispatch({
          type: SET_OFFER_LIST,
          payload: [[], [],responseJson.data.items, first6]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const fetchCategories = () => {
  // console.log("fetchCategories");
  return (dispatch, getState) => {
    const { stores, cards } = getState()

    // console.log("fetchCategories dispatch");
    MembersService.getCoupons(0, 1)
      .then((responseJson) => {
        dispatch({
          type: SET_OFFER_LIST,
          payload: responseJson.data.items.slice(0, 6)
        });
      })
      .catch((error) => {
        console.log(error);
      });
    MembersService.getCategories(
      stores.selectedStore ? stores.selectedStore.retailer_id : cards && cards.holders && cards.holders.length > 0 ? cards.holders[0].retailer_id : -1
    )
      .then((responseJson) => {
        if (responseJson.data.length) {
          const allCategories = [...responseJson.data];
          
          // Sort categories by precedence
          allCategories.sort((a, b) => a.precedence - b.precedence);
          
          // Add "All" category at the beginning
          allCategories.unshift({
            id: 1,
            name: "全て",
            tag: "All",
            precedence: 0,
          });

          dispatch({
            type: SET_CATEGORIES,
            payload: allCategories
          });
        }
      })
      .catch((error) => {
        console.error('Failed to fetch categories:', error);
      });
  };
};

export const fetchCategoriesForHomePage = () => {
  // console.log("fetchCategoriesForHomePage");
  return dispatch => {
    // console.log("fetchCategoriesForHomePage dispatch");

    MembersService.getCategoriesForHomePage()
      .then((responseJson) => {
        if (responseJson.data.length) {
          const allCategories = [];
          let categories = allCategories.concat(responseJson.data)
          categories.sort(function (a, b) {
            if (a.precedence > b.precedence) {
              return 1;
            } else if (a.precedence < b.precedence) {
              return -1;
            } else {
              return 0;
            }
          });
          categories.unshift({
            id: 1,
            name: "全て",
            tag: "All",
            precedence: 0,
          });
          // const allCategories = [];
          // allCategories.push({
          //     id: 1,
          //     name: "全て",
          //     tag: "All",
          //     precedence: 0,
          // });

          dispatch({
            type: SET_CATEGORIES,
            payload: categories
          });

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
// MembersService.getCategories()
//     .then((responseJson) => {
//       if (responseJson.data.length) {
//         const allCategories = [];
//         allCategories.push({
//           id: 1,
//           name: "全て",
//           tag: "All",
//           precedence: 1,
//         });
//         props.setCategories(allCategories.concat(responseJson.data));
//         setCategoriesLoaded(true);
//         fetchFavoriteStores();
//       }
//     })
//     .catch((error) => {
//       setCategoriesLoaded(true);
//       console.log(error);
//     });
export const setBasketList = (offers) => {
  return {
    type: SET_BASKET_LIST,
    payload: offers,
  };
};
export const fetchBasket = () => {
  return (dispatch, getState) => {
    const { stores } = getState()

    MembersService.getBasket(
      stores.selectedStore ? stores.selectedStore.id : 0,
      stores.selectedStore ? stores.selectedStore.retailer_id : 0
    )
      .then((responseJson) => {
        dispatch({
          type: SET_BASKET_LIST,
          payload: responseJson.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
