import axios from "axios";
import UtilityService from "./utility";
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
const OCP_API_SUBSCRIPTION_KEY= process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY as string;
const MEMBERS_URL= process.env.REACT_APP_MEMBERS_URL as string;
const RETAILERS_URL= process.env.REACT_APP_RETAILERS_URL as string;
const BASE_URL= process.env.REACT_APP_BASE_URL as string;
const INQUIRY_BASE_URL= process.env.REACT_APP_INQUIRY_BASE_URL as string;
// Add a request interceptor
axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);

const getLast36Months = () => {
  let d = new Date();
  const dates = [];
  for (let i = 0; i < 36; i++) {
    let dateString = d.toISOString().split("-");
    let year = dateString[0];
    let month = dateString[1];
    dates.push(`${year}/${month}`);
    d.setMonth(d.getMonth() - 1);
  }
  return dates;
};

const getRequestHeadersForHomePage = () => {
  const user = JSON.parse(localStorage.getItem("user-data")!);
  const headers: any = {
    "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": "true",
    "Content-Type": "application/json",
  };
  if (user) {
    headers.Authorization = `Bearer ${user.access_token}`;
  }
  return headers;
};

const getRequestHeaders = () => {
  const user = JSON.parse(localStorage.getItem("user-data")!);
  if(user && user.access_token){
    return {
      "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
      "Ocp-Apim-Trace": "true",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.access_token}`,
    };
  } else {
    if(window.location.pathname=='/1'){

    } else {
      window.location.href = '/1'
    }
  }

};

const getUser = () => {
  const user = JSON.parse(localStorage.getItem("user-data")!);
  if(user){
    return user
  } else{
    if(window.location.pathname=='/1'){

    } else {
      window.location.href = '/1'
    }
  }
};

const MembersService = {
  registerCard: (cardNumber: string, retailerId: any) => {
    const user = getUser();
    return axios.post(
      `${MEMBERS_URL}/${user.id}/holders`,
      JSON.stringify({
        ref: cardNumber,
        retailer_id: retailerId,
      }),
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  updateCard: (holderId: number, cardNumber: string, retailerId: any) => {
    const user = getUser();
    return axios.patch(
      `${MEMBERS_URL}/${user.id}/holders/${holderId}`,
      JSON.stringify({
        ref: cardNumber,
        retailer_id: retailerId,
      }),
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  patchMember: (userDetails: any) => {
    const user = getUser();
    return axios.patch(
      `${MEMBERS_URL}/${user.id}`,
      JSON.stringify(userDetails),
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  patchPaperless: (retailer: any, is_checked: boolean) => {
    const user = getUser();
    return axios.put(
        `${BASE_URL}/paperless/${user.id}/${retailer.id}?is_paperless=${is_checked}`,
        {member_id:user.id, retailer_id:retailer.id,is_paperless:is_checked},
        { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  deleteMember: () => {
    const user = getUser();
    return axios.delete(
      `${MEMBERS_URL}/${user.id}`,
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  inquiry: (email: string, firstname: string, lastname: string, message: string) => {
    return axios.get(
      `${INQUIRY_BASE_URL}?email=${email}&message=${message}&subject=クーポンプラスより&first_name=${firstname}&last_name=${lastname}`
      // { headers: getRequestHeaders() }
    );
  },
  deleteCard: (holderId: number) => {
    const user = getUser();
    return axios.delete(`${MEMBERS_URL}/${user.id}/holders/${holderId}`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  addCouponToBasket: (couponId: number, store: any) => {
    const user = getUser();
    return axios.post(
      `${MEMBERS_URL}/${user.id}/basket`,
      JSON.stringify([
        {
          offer_id: couponId,
          retailer_id: store.retailer_id,
          store_id: store.id,
        },
      ]),
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  removeCouponFromBasket: (reward_id: number) => {
    const user = getUser();
    return axios.delete(
      `${MEMBERS_URL}/${user.id}/basket?reward_id=${reward_id}`,
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  // // DELETE /members/{member_id}/stores/{store_id}
  removeStoreFromFavs: (store_id: number) => {
    const user = getUser();
    return axios.delete(`${MEMBERS_URL}/${user.id}/stores/${store_id}`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },

  // // Add /members/{member_id}/stores/{store_id}

  addStoreToFavs: (store_id: number, store:any) => {
    if(store){
      localStorage.setItem('fav-store', JSON.stringify(store))
    }
    const user = getUser();
    return axios.post(
      `${MEMBERS_URL}/${user.id}/stores`,
      JSON.stringify({
        id: store_id,
      }),
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },

  getCoupons: (storeId: number, categoryId?: number, retailerId?: number) => {
    const user = getUser();
    let url =
    `${BASE_URL}/offers?per_page=1000`;
    if (user) {
      url = url + '&member_id=' + user.id;
    }
    if (storeId !== 0) {
      url = url + "&store_id=" + storeId;
    }

    if (categoryId !== 1) {
      url = url + "&category_id=" + categoryId;
    }

    if (retailerId !== -1) {
      url = url + "&retailer_id=" + retailerId;
    }
    return axios.get(url, { timeout: UtilityService.timeOut, headers: getRequestHeaders() });
  },

  getCouponsForHomePage: (categoryId?: number) => {
    let url = `${BASE_URL}/offers?per_page=1000&`;
    if (categoryId !== 1) {
      url = url + "category_id=" + categoryId;
    }
    return axios.get(url, { timeout: UtilityService.timeOut, headers: getRequestHeadersForHomePage() });
  },
  getSingleCoupon: (offerId: any) => {
    let url =  `${BASE_URL}/offers/${offerId}`;
    return axios.get(url, { timeout: UtilityService.timeOut, headers: getRequestHeaders() });
  },
  getCouponDetail: (offerId: number) => {
    let url = `${BASE_URL}/offers/${offerId}`;
    return axios.get(url, { timeout: UtilityService.timeOut, headers: getRequestHeaders() });
  },
  getBasket: (storeId: number, retailer_id:number) => {
    const user = getUser();
    let url = `${MEMBERS_URL}/${user.id}/basket?`;

    if (storeId !== 0) {
      url = url + "store_id=" + storeId;
    }
    if (retailer_id !== 0) {
      url = url + "&retailer_id=" + retailer_id;
    }
    return axios.get(url, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  getFavoriteStores: () => {
    const user = getUser();
    return axios.get(`${MEMBERS_URL}/${user.id}/stores`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  getRetailers: () => {
    return axios.get(`${RETAILERS_URL}?per_page=1000`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  getMember: () => {
    const user = getUser();
    if(user)
    return axios.get(`${MEMBERS_URL}/${user.id}`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  getHolders: () => {
    const user = getUser();
    if(user)
    return axios.get(`${MEMBERS_URL}/${user.id}/holders`, {
      timeout: UtilityService.timeOut,
      headers: getRequestHeaders(),
    });
  },
  getWallet: (start?: string, end?: string, retailer_id?: string) => {
    const user = getUser();
    const dates = getLast36Months();
    // props.mode === "START" ? dates[dates.length - 1] : dates[0]
    if(!start) {
      start =  dates[6];
    }

    if(!end) {
      end = dates[0];
    }

    start = start + "/01";
    end =  UtilityService.fetchEndDate(end);
    // end = end + "/30";
    let query = ''
    if(retailer_id){
      query = `&retailer_id=${retailer_id}`
    }
    return axios.get(
      `${MEMBERS_URL}/${
        user.id
      }/wallet?from=${new Date(start).toISOString()}&to=${new Date(end).toISOString()}${query}`,
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },
  getCategories: (retailerId?: number) => {
    const user = getUser();
    let url = `${BASE_URL}/categories?`;
    if (user) {
      url = url + `member_id=${user.id}&`
    }
    if (retailerId !== -1) {
      url = url + "retailer_id=" + retailerId;
    }
    return axios.get(
      url,
      { timeout: UtilityService.timeOut, headers: getRequestHeaders() }
    );
  },

  getCategoriesForHomePage: () => {

    let url = `${BASE_URL}/categories`;
    return axios.get(
        url,
        { timeout: UtilityService.timeOut, headers: getRequestHeadersForHomePage() }
    );
  },
};


(window as any).MemberService = MembersService;

export default MembersService;
